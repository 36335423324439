import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CTASection from "../CTASection";
import { styles } from "./styles";
import { ChildImageSharp } from "../../interfaces/images";

const LinkCTAs = () => {
  const data: {
    linksImage: ChildImageSharp;
    doodlesImage: ChildImageSharp;
  } = useStaticQuery(graphql`
    {
      linksImage: file(relativePath: { eq: "linksImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      doodlesImage: file(relativePath: { eq: "doodlesImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const catContent =
    "Cat Pearson is a doodler, designer and mum based in east London. After crying and laughing her way through Series One, Cat hooked up with Helen to help her bring more positive and honest stories to life through illustration.";
  return (
    <div css={styles.container}>
      <CTASection
        title="Links & resources"
        content="I've found it hard to find much in the way of support for people with pelvic floor dysfunction. Here are a few links I've pulled together."
        layout="vertical"
        theme="light"
        cta={{
          href: "/blog/resources",
          content: "Check out some links"
        }}
        image={data.linksImage}
      />
      <CTASection
        title="The doodles"
        content={catContent}
        layout="vertical"
        theme="light"
        cta={{
          href: "http://www.catherinepearson.ie/",
          content: "Find Cat's website here"
        }}
        image={data.doodlesImage}
      />
    </div>
  );
};

export default LinkCTAs;
