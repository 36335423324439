import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";
import { variables } from "../../styles/variables";

const { centeredContainer } = variables;

export const styles: EmotionStyles = {
  container: {
    [breakpointMixins.md]: {
      ...centeredContainer,
      display: "flex",
      justifyContent: "space-between"
    },

    "& .cta-section-image": {
      maxWidth: 120,
      minWidth: "auto",
      marginLeft: variables.spacing.medium,
      [breakpointMixins.md]: {
        maxWidth: 180,
        minWidth: 180,
        marginLeft: "0"
      }
    }
  }
};
