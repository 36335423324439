import React from "react";
import { Author } from "../../interfaces/blogPost";
import { EmotionStyles } from "../../interfaces/emotion";

import AuthorCard from "../AuthorCard";
import HelenCard from "../HelenCard";
export interface AuthorListProps {
  items: Array<Author>;
}

const styles: EmotionStyles = {
  list: {
    listStyleType: "none",
    margin: 0,
    padding: 0
  },
  author: {
    display: "flex"
  }
};

const AuthorList: React.FC<AuthorListProps> = ({ items }) => {
  if (items.length === 0) return null;
  return (
    <div>
      <ul css={styles.list}>
        {items.map((author, i) => {
          return (
            <li css={styles.author} key={author._key || i}>
              {author.author.name === "Helen Ledwick" ? (
                <HelenCard />
              ) : (
                <AuthorCard
                  author={author}
                  cta={
                    author.author.cta
                      ? {
                          href: author.author.cta,
                          content: `More about ${author.author.name}`
                        }
                      : null
                  }
                  testId="author-card"
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AuthorList;
