import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { AuthorProperties } from "../../interfaces/blogPost";
import { ChildImageSharp } from "../../interfaces/images";
import CTASection, { CTASectionProps } from "../CTASection";

interface HelenCardData {
  author: AuthorProperties;
  helenImage: ChildImageSharp;
}

type HelenCardProps = Pick<
  CTASectionProps,
  "title" | "collapseAllPadding" | "collapseBottomPadding" | "content"
>;

const HelenCard: React.FC<HelenCardProps> = ({
  content,
  title,
  collapseAllPadding,
  collapseBottomPadding
}) => {
  const data: HelenCardData = useStaticQuery(graphql`
    {
      author: sanityAuthor(name: { eq: "Helen Ledwick" }) {
        name
        cta
        _rawBio
        image {
          asset {
            _id
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
          }
        }
      }
      helenImage: file(relativePath: { eq: "helen.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const { helenImage } = data;
  return (
    <CTASection
      cta={{
        href: "/about",
        content: "More about me"
      }}
      title={title || "Hello"}
      content={
        content ||
        `"Hi. I'm Helen Ledwick. I started this podcast when my pelvic organs took a nosedive."`
      }
      image={helenImage}
      collapseBottomPadding={collapseBottomPadding}
      collapseAllPadding={collapseAllPadding}
    />
  );
};

export default HelenCard;
