import { CSSObject } from "@emotion/core";
import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";
import { colors } from "../../styles/colors";
import { contentStyles } from "../../styles/content";
import { textStyles } from "../../styles/type";
import { variables } from "../../styles/variables";

const { spacing, centeredContainer, contentWidth } = variables;

const headerStyles: CSSObject = {
  ...textStyles.headingS,
  textTransform: "uppercase",
  marginTop: "3rem",
  marginBottom: spacing.medium,

  [breakpointMixins.lg]: {
    marginTop: "4rem"
  }
};
export const styles: EmotionStyles = {
  container: {
    paddingTop: spacing.medium,

    [breakpointMixins.lg]: {
      paddingTop: spacing.large
    }
  },
  inner: {
    maxWidth: contentWidth
  },
  featuredImageContainer: {
    margin: `${spacing.large} auto`,
    maxWidth: 650
  },
  title: {
    color: colors.body,
    maxWidth: 650
  },
  content: {
    zIndex: 10,
    ...contentStyles
  },
  postFooter: {
    marginTop: spacing.large
  },
  featuredEpisodeContainer: {
    ...centeredContainer,
    maxWidth: contentWidth,
    marginTop: spacing.large,
    paddingBottom: spacing.large
  },
  featuredEpisodeTitle: {
    ...headerStyles
  }
};
