import { BlogPostFeaturedEpisode } from "../interfaces/blogPost";
import { TransistorEpisodeInterface } from "../interfaces/podcasts";

export const getUrlSlug = (baseUrl: string) => {
  const slugIndex = baseUrl.lastIndexOf("/");
  return baseUrl.substr(slugIndex + 1);
};

export const getFeaturedEpisode = (
  episodeList: Array<TransistorEpisodeInterface>,
  featuredEpisode: BlogPostFeaturedEpisode
) => {
  if (!featuredEpisode || episodeList.length === 0) return;

  return episodeList.find(
    ({ attributes }) => attributes.title === featuredEpisode.episode
  );
};
