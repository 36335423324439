import React from "react";
import { Author, AuthorProperties } from "../../interfaces/blogPost";
import CTASection, { CTASectionProps } from "../CTASection";
import Figure from "../Figure";
import { styles } from "./authorcardStyles";

export interface AuthorProps extends CTASectionProps {
  author: Author;
}

const AuthorImage: React.FC<Pick<AuthorProperties, "image">> = ({ image }) => {
  if (!image) return null;
  return (
    <div css={styles.avatarOuter}>
      <div css={styles.avatarInner}>
        <Figure node={image} styles={styles.image} />
      </div>
    </div>
  );
};

export const AuthorCard: React.FC<AuthorProps> = ({
  author: { author },
  title,
  image,
  content,
  cta,
  collapseBottomPadding,
  ...rest
}) => {
  return (
    <CTASection
      cta={
        cta
          ? cta
          : author.cta
          ? {
              href: cta || author.cta,
              content: `More about ${author.name}`
            }
          : null
      }
      title={title || author.name}
      content={content || author._rawBio}
      image={image || <AuthorImage image={author.image} />}
      collapseBottomPadding={collapseBottomPadding}
      {...rest}
    />
  );
};

export default AuthorCard;
