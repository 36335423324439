import { EmotionStyles } from "../../interfaces/emotion";
import { variables } from "../../styles/variables";

const { spacing } = variables;

export const styles: EmotionStyles = {
  avatarOuter: {
    padding: spacing.small,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginRight: variables.spacing.medium
  },
  avatarInner: {
    margin: 0,
    borderRadius: "50%",
    overflow: "hidden",
    width: "100%"
  }
};
