import React from "react";
import AuthorList from "../AuthorList";
import PortableText from "../PortableText";
import Heading from "../Heading";
import EpisodeList from "../EpisodeList";
import LinkCTAs from "../LinksCTAs";

import { BlogPostInterface } from "../../interfaces/blogPost";

import { styles } from "./styles";
import { graphql, useStaticQuery } from "gatsby";
import { getFeaturedEpisode } from "../../helpers/getFeaturedEpisode";
import Inner from "../Inner";
import BuyMeACoffee from "../BuyMeACoffee";

export interface BlogPostProps extends BlogPostInterface {
  tags?: Array<string>;
}

const BlogPost: React.FC<BlogPostProps> = ({
  _rawBody,
  authors,
  title,
  episode
}) => {
  const data = useStaticQuery(graphql`
    query FeaturedEpisodesQuery {
      podcasts: allTransistorEpisode {
        nodes {
          id
          ...TransistorEpisodeAttributes
        }
      }
    }
  `);
  const featuredEpisode = getFeaturedEpisode(data.podcasts.nodes, episode);

  return (
    <article css={styles.container}>
      <Inner cssStyles={[styles.inner]}>
        <Heading headingLevel="h1" styles={styles.title}>
          {title}
        </Heading>
        <div css={styles.content}>
          {_rawBody && <PortableText blocks={_rawBody} />}
          <BuyMeACoffee />
        </div>
      </Inner>
      <aside css={styles.postFooter} title="blog post footer">
        {featuredEpisode && (
          <Inner cssStyles={[styles.inner]}>
            <div css={styles.featuredEpisodeContainer}>
              <Heading
                headingLevel="h3"
                styles={styles.featuredEpisodeTitle}
                italic={false}
              >
                Listen to the podcast
              </Heading>
              <EpisodeList
                episodes={[featuredEpisode]}
                displayDescriptions={false}
                collapsePadding={true}
              />
            </div>
          </Inner>
        )}
        {authors && <AuthorList items={authors} />}
      </aside>
      <LinkCTAs />
    </article>
  );
};

export default BlogPost;
