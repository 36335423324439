import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import BlogPost from "../components/BlogPost";
import SEO from "../components/SEO";
import { toPlainText } from "../helpers/content";
import { BlogPostInterface } from "../interfaces/blogPost";
import { SiteInterface } from "../pages";

interface BlogPostTemplateProps {
  data: {
    site: SiteInterface;
    post: BlogPostInterface;
  };
  errors: Array<GraphQLError>;
}

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    post: sanityPost(id: { eq: $id }) {
      ...BlogPostFragment
    }
  }
`;

const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({
  data,
  errors
}) => {
  const site = data && data.site;
  const post = data && data.post;
  return (
    <React.Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={`${post.title || "Untitled"} | ${site.title}`}
          description={toPlainText(post._rawExcerpt)}
          socialImage={
            post.mainImage?.asset ? { asset: post.mainImage?.asset } : undefined
          }
        />
      )}

      {errors && <GraphQLErrorList errors={errors} />}

      {post && <BlogPost {...post} tags={site.keywords} />}
    </React.Fragment>
  );
};

export default BlogPostTemplate;
